<template>
  <div>
    <a-modal
      v-model="visible"
      :title="action + ' Api Key'"
      :dialogStyle="{ top: '10px' }"
      :destroyOnClose="true"
    >
      <template slot="footer">
        <a-button class="btn btn-outline-dark" @click="handleCancel">
          Cancel
        </a-button>
        <a-button
          class="btn btn-outline-primary"
          :loading="loading"
          @click="submit('ruleForm')"
        >
          Submit
        </a-button>
      </template>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="row">
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <label class="mt-1">BPR</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
            <a-select
              v-model="form.bpr_id"
              show-search
              class=""
              placeholder="Pilih BPR"
              option-filter-prop="children"
              style="width: 80%;"
              :filter-option="filterOption"
            >
              <a-select-option v-for="(data, index) in dataBPR" :key="index" :value="data.id">
                {{ data.namabpr }}
              </a-select-option>
            </a-select>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <label class="mt-1">App</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
            <a-select
              v-model="form.app_id"
              show-search
              class=""
              placeholder="Pilih App"
              option-filter-prop="children"
              style="width: 80%;"
              :filter-option="filterOption"
            >
              <a-select-option v-for="(data, index) in dataApp" :key="index" :value="data.id">
                {{ data.appName }}
              </a-select-option>
            </a-select>

            </div>
          </div>
          <div class="row mt-3">
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <label class="mt-1">Data</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              <a-input class="" v-model="form.data" style="width: 70%"></a-input>
              <a-button @click="generateKey" class="btn btn-outline-secondary pull-right">Generate Key</a-button>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <label class="mt-1">Status</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              <a-switch default-checked v-model="form.status"/>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'

export default {
  name: 'editprofile',
  // created() {
  // },
  data() {
    // const validatePass = (rule, value, callback) => {
    //   // if (this.action === 'Create') {
    //   //   if (this.form.password_confirmation !== '') {
    //   //     this.$refs.ruleForm.validateField('checkPass')
    //   //   }
    //   //   callback()
    //   // } else {
    //   if (value === '') {
    //     callback(new Error('Please input the password'))
    //   } else {
    //     if (this.form.password_confirmation !== '') {
    //       this.$refs.ruleForm.validateField('checkPass')
    //     }
    //     callback()
    //   }
    //   // }
    // }
    // const validatePass2 = (rule, value, callback) => {
    //   // if (this.action === 'Create') {
    //   //   if (value !== this.form.password) {
    //   //     callback(new Error("Password doesn't match!"))
    //   //   } else {
    //   //     callback()
    //   //   }
    //   // } else {
    //   if (value === '') {
    //     callback(new Error('Please input the password again'))
    //   } else {
    //     if (value !== this.form.password) {
    //       callback(new Error("Password doesn't match!"))
    //     } else {
    //       callback()
    //     }
    //   }
    //   // }
    // }
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      user: [],
      datarole: [],
      dataBPR: [],
      dataApp: [],
      roletags: false,
      editdata: {},
      action: 'Create',
      form: {
        bpr_id: '',
        app_id: '',
        data: '',
        status: true,
      },
      rules: {
        kode: [{ required: true, message: 'Harap inputkan kode!' }],
        sandi_kantor: [{ required: true, message: 'Harap inputkan sandi kantor!' }],
        alamat: [{ required: true, message: 'Harap inputkan alamat!' }],
        // keterangan: [{ required: true, message: 'Harap inputkan Keterangan!' }],
        induk: [{ required: true, message: 'Harap inputkan nomor induk!' }],
        telp: [{ required: true, message: 'Harap inputkan nomor telepon!' }],
        kota: [{ required: true, message: 'Harap inputkan kota!' }],
      },
      loading: false,
      visible: false,
      datenow: null,
    }
  },
  methods: {
    moment,
    async getMaster() {
      var resp = await lou.customUrlGet('manage/bank', false, false, false, false)
      var resp1 = await lou.customUrlGet('manage/app', false, false, false, false)
      this.dataBPR = resp.data
      this.dataApp = resp1.data
    },
    selectDate(selectedDates, dateString, input) {
      // console.log('selectedDates', selectedDates)
      // console.log('dateString', dateString)
      // console.log('input', input)
      if (selectedDates !== null) {
        this.form[input] = dateString
      } else {
        this.form[input] = null
      }
    },
    userAppSelect(e) {
      // console.log('e', e)
      var temp = []
      for (let m = 0; m < e.length; m++) {
        const element = e[m]
        temp.push({ id_app: element })
      }
      // console.log('temp', temp)
      this.form.appLists = temp
    },
    generateKey() {
      // this.form.data = this.$uuid.v4()
      this.generate()
    },
    generate () {
      var char = 'a-z,A-Z,0-9,#'
      const charactersArray = char.split(',')
      let CharacterSet = ''
      let password = ''

      if (charactersArray.indexOf('a-z') >= 0) {
        CharacterSet += 'abcdefghijklmnopqrstuvwxyz'
      }
      if (charactersArray.indexOf('A-Z') >= 0) {
        CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      }
      if (charactersArray.indexOf('0-9') >= 0) {
        CharacterSet += '0123456789'
      }
      if (charactersArray.indexOf('#') >= 0) {
        CharacterSet += '![]{}()%&*$#^<>~@|'
      }

      for (let i = 0; i < 24; i++) {
        password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length))
      }
      this.form.data = password
    },
    async showModal(action, data = {}) {
      this.getMaster()
      // console.log('data', data)
      this.editdata = data
      // var resbpr = await lou.customUrlGet('auth/sandibpr', false, true)
      // this.databpr = resbpr.data
      if (action === 'Update') {
        this.form = {
          id: data.id,
          bpr_id: data.bpr_id,
          app_id: data.app_id,
          data: data.data,
          status: data.status,
        }
        // console.log('this.form.selectedAppLists', this.form.selectedAppLists)
      } else {
        this.form = {
          bpr_id: '',
          app_id: '',
          data: '',
          status: true,
        }
      }
      this.action = action
      this.visible = true
    },
    handleCancel() {
      this.visible = false
    },
    async submit(formName) {
      // this.$refs[formName].validate(async (valid) => {
      // if (valid) {
      // console.log('this.form.appLists', this.form.appLists)
      var fd = this.$g.clone(this.form)
      if (this.action === 'Create') {
        await lou.customUrlPost2('manage/apikey', fd, true, true)
      } else {
        // fd.id = this.form.id
        await lou.customUrlPut2('manage/apikey', fd, true, true)
      }
      this.$parent.getData()
      this.visible = false
      this.resetForm(formName)
      // } else {
      // console.log('error submit!!')
      // return false
      // }
      // })
    },
    resetForm(formName) {
      this.editdata = {}
      this.form = {
        bpr_id: '',
        app_id: '',
        data: '',
        status: true,
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>

<style></style>
